import _c from "./colors"
// import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

const VendingLocationsWebappTheme = createMuiTheme({
  palette: {
    primary: {
      main: _c.main_red,
    },
    secondary: {
      main: _c.black,
    },
    error: {
      main: "#FF0000",
    },
    background: {
      default: _c.screen_background,
    },
  },
  // typography: {
  //   fontFamily: ["Anton", "Helvetica", "Roboto", "sans-serif"].join(","),
  // },
})

export default VendingLocationsWebappTheme

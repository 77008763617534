import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "@material-ui/core/styles"
import VendingLocationsWebappTheme from "../uiConfig/theme"
import styled from "styled-components"
import _c from "../uiConfig/colors"

export default function MetaHeader({ children }) {
  return (
    <ThemeProvider theme={VendingLocationsWebappTheme}>
      <Helmet defer={false}>
        <script src="https://js.stripe.com/v3/"></script>
        <meta charSet="utf-8" />
      </Helmet>
      <Main>{children}</Main>
    </ThemeProvider>
  )
}

const Main = styled.div`
  overflow: auto;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  background-color: ${_c.screen_background};
  flex-direction: row;
  padding: 0;
`

const _c = {
  main_red: "#D83401",
  screen_background: "#FCFBFD",
  neutral_grey: "#F4F4F5",
  greyBackground: "rgba(242, 242, 247, 0.4)",
  black: "#000",
  white: "#FFF",
  btnDisabled: "rgba(116, 116, 128, 0.08)",
  btnDisabledTxt: "rgba(60, 60, 67, 0.3);",
  greyTxtLight: "rgba(60, 60, 67, 0.6)",
  greyTxt: "rgba(60, 60, 67, 0.29)",
  darkGrey: "#3A3A3C",
  blueTxt: "#007AFF",
  success_green: "#4CD964",
  darkMainRed: "#D51F1F",
  darkerMainRed: "#960E0E",
  markerGrey: "#C8C7CC",
  darkMarker: "#3A3A3C",
  yellow: "#F9AE49",
  cautionRed: "#FF3B30",
}
export default _c
